import {firestore} from "../firebase";

const db = firestore.collection("/kunjungan");

const getRegistered = (gdate,ldate) => {
  return db.orderBy("daftarjam").where("daftarjam",">",gdate).where("daftarjam","<",ldate);
};

const create = (data) => {
  return db.add(data);
};

const update = (id, value) => {
  return db.doc(id).update(value);
};

const remove = (id) => {
  return db.doc(id).delete();
};

const checkExist = async (id) => {
  const docRef = db.doc(id);
  const docSnap = await docRef.get();

  if (docSnap.exists) {
    return true;
  } else {
    return false;
  }
};

const KunjunganService = {
  getRegistered,
  create,
  update,
  remove,
  checkExist
};

export default KunjunganService;